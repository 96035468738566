<script>
  /**
   * errorMessage is a global store, so when navigating between pages the error state persists
   * unless it is manually cleared on each page.
   *
   * This clears the error state on a route change such that each page does not need to clear any
   * previous error messages.
   *
   * This also clears the breadcrumb paths in the same manner.
   */

  import { urlHistory, isGTMSetupReady } from '../stores/core-store';
  import { setupGoogleAnalytics, sendPageViewEvent } from '../util/google-analytics';
  import { GTM_ID } from '../config';

  setupGoogleAnalytics(GTM_ID);

  urlHistory.subscribe(async (history) => {
    window.scrollTo(0, 0);

    if (history.length >= 1 && history[history.length - 1] !== history[history.length - 2]) {
      const path = history[history.length - 1];
      // Before GTM setup
      if (!$isGTMSetupReady) {
        setTimeout(async () => {
          await sendPageViewEvent(path);
        }, 200);
      } else {
        // After GTM setup
        await sendPageViewEvent(path);
      }
    }
  });
</script>

<style>
</style>
